
export default (function () {
    var blockService = null;
    return {
        $loading: null,
        Start() {
            blockService = this.$loading({
                // spinner: 'hourglass',
                fullscreen: true,
                // spinner: 'dual-ring',
                text:'جاري التحميل',
                // background: "rgba(0, 0, 0, 0.7)"
            });
        },
        Stop() {
            blockService.close();
        }
    };
})();



