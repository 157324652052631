export default {
    name: 'footerbar',
    components:{
    },
    created(){
    },
    data() {
        return {
         
        };
    },
}