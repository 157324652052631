import Vue from "vue";
import VueRouter from "vue-router";
import app from "@/components/app/app.vue";
import layout from '@/components/layout/layout.vue';
import { createPinia } from 'pinia'
import { storeToRefs } from 'pinia';
import { useStore } from '@/store/index';

const pinia = createPinia();
Vue.use(VueRouter);
Vue.use(pinia);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/login/login.vue"),
    meta: { requiresAuth: false  },
},
  { 
    path: "/",
    component: app,
    children: [
      {
        path: "",
        component: layout,
        children: [
          {
            path: "/",
            name: "home",
            component: () => import("@/components/home/home.vue"),
            meta: { requiresAuth: true  },
          },
          {
            path: "/users/:userType",
            name: "users",
            props: true,
            component: () => import("@/components/users/users.vue"),
            meta: { requiresAuth: true  },
          },
          {
            path: "/activities",
            name: "activities",
            component: () => import("@/components/activities/activities.vue"),
          },
          {
            path: "/activityresults",
            name: "activityresults",
            component: () => import("@/components/activityresults/activityresults.vue"),
          },
          {
            path: "/addActivity",
            name: "addActivity",
            component: () => import("@/components/addActivity/addActivity.vue"),
            meta: { requiresAuth: true  },
          },
          {
            path: "/changePassword",
            name: "changePassword",
            component: () => import("@/components/changePassword/changePassword.vue"),
          },  
          {
            path: "/files",
            name: "files",
            component: () => import("@/components/files/files.vue"),
          }, 
          {
            path: "/instructions",
            name: "instructions",
            component: () => import("@/components/instructions/instructions.vue"),
          }, 
          {
            path: "/removedActivities",
            name: "removedActivities",
            component: () => import("@/components/removedActivities/removedActivities.vue"),
          }, 
          {
            path: "/canceledActivities",
            name: "canceledActivities",
            component: () => import("@/components/canceledActivities/canceledActivities.vue"),
          },
          {
            path: "/reports",
            name: "reports",
            component: () => import("@/components/reports/reports.vue"),
          }, 
        ],
      },
    ],
  },
];

// const router = new VueRouter({
//   mode: 'history',
//   base: __dirname,
//   linkExactActiveClass: 'active',
//   routes
// })

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const ref = storeToRefs(store);
 // console.log(ref.getUserData.value.UserType);
  if (to.meta.requiresAuth && !ref.isAuthenticated.value) {
    next({
      name: 'login',
      replace: true
    }) 
  } 
  else {
   if (to.meta.requiresAuth) {
      if (ref.getUserData.value.UserType != 1 && to.path == "/") {
        next({
          name: 'activities',
          replace: true
        })
      }
     
      if (ref.getUserData.value.UserType != 1 && to.name == 'users') {
        next({
          name: 'activities',
          replace: true
        })
      }
      if (ref.getUserData.value.UserType == 1 && to.path == "/addActivity") {
        next({
          name: 'home',
          replace: true
        })
      }
    }

 //  next();
  }
 next();
})
export default router;
