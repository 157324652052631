import { useStore } from '@/store/index';
export default {
    name: 'navbar',
    components:{
    },
    created() {
        this.name = useStore().getUserData.Name;
        this.imagePath = useStore().getUserData.ImagePath;
    },
    data() {
        return {
            name:null,
            imagePath:null
        };
    },
    methods: {
    toggleSidebar: () => {
        document.querySelector('body').classList.toggle('sidebar-icon-only');
      },
      toggleMobileSidebar: () => {
        document.querySelector('#sidebar').classList.toggle('active');
      },
      href(url){
        this.$router.push(url);
        },
        logout() {
            this.$blockUI.Start();
            this.$http.get(`api/Authorization/Logout`).then(response => {
                this.$blockUI.Stop();
                window.location.href = '/login';
            }).catch(e => {
                this.$blockUI.Stop();
                this.$message.error(e.response.data)
            })
            // window.$cookies.remove('user-credentials');
            // localStorage.removeItem('hr-system');
        }
    }
}
