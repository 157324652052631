import { useStore } from '@/store/index';
export default {
    name: 'sidebar',
    components:{
    },
    created(){
        this.userType = useStore().getUserData.UserType;
        this.name = useStore().getUserData.Name;
        if (this.userType == 1) {
            this.jopTitle = "مدير النظام";
        }
        else if (this.userType == 2) {
            this.jopTitle = "منسق التوعية";
        }
        else if (this.userType == 3) {
            this.jopTitle = "سفيرة الجيل الأول";
        }
        else if (this.userType == 4) {
            this.jopTitle = "سفيرة الجيل الثاني";
        }
        this.imagePath = useStore().getUserData.ImagePath;
    },
    data() {
        return {
            userType: 0,
            name: null,
            jopTitle: null,
            imagePath:null
        };
    },
    methods:{
        href(url){
            this.$router.push(url);
        },
        logout(){
            this.$blockUI.Start();
            this.$http.get(`api/Authorization/Logout`).then(response => {
            this.$blockUI.Stop();
            window.location.href = '/login';            
            }).catch(e => {
                this.$blockUI.Stop();
                this.$message.error(e.response.data)
            })         
        }
    }
}