import Vue from 'vue'
import app from './components/app/app.vue'
import router from './router'
import {createPinia} from 'pinia'
import store from './store'

import ElementUI from 'element-ui'
import VueCookies from 'vue-cookies'
import locale from 'element-ui/lib/locale/lang/ar'

//-------css------
require('./../node_modules/element-ui/lib/theme-chalk/index.css');

//---------js-----

import httpService from './services/httpService'
import loading from './services/loadingUIService'

Vue.config.productionTip = false

Vue.prototype.$http = httpService;
Vue.prototype.$blockUI = loading;
Vue.use(ElementUI, { locale });
Vue.use(VueCookies, { expire: '8h' });
Vue.use(createPinia());

new Vue({
    router,
    store,
    render: h => h(app)
}).$mount('#app')
