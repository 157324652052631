//import Vue from 'vue'
//import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import { defineStore } from 'pinia';
export const useStore = defineStore('main', {
  state: () => ({ token: null, userData: null }),
  getters: {
    getToken: (state) => {
      if (window.$cookies.isKey('user-credentials')) {
        state.token = window.$cookies.get('user-credentials');
        return state.token;
      }
      return { token: null }
    }, 
    getUserData: (state) => {
      state.userData = JSON.parse(localStorage.getItem('hr-system'));
      return state.userData;
    },
      isAuthenticated: () => {
      return window.$cookies.isKey('user-credentials');
    }
  },
  actions: {
    removeUserData: (state) => {
 
          state.userData = null;
          state.token = null;
          localStorage.removeItem('hr-system');
          window.$cookies.remove('user-credentials');
    },

  }
})