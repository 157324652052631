import axios from 'axios';
import { useStore } from '@/store/index';
import { storeToRefs } from 'pinia';
//const baseUrl = "http://localhost:8080/"
 //axios.defaults.baseURL = process.env.VUE_APP_URL;
//axios.defaults.baseURL = "https://localhost:7085";
// axios.defaults.baseURL = "http://10.100.101.32:4000";
var $apiURL="https://mynet.com.ly"
//var $apiURL="https://localhost:7085"
axios.defaults.baseURL = $apiURL;



var x = "api/Authorization/Login";

axios.interceptors.request.use(config => {

    if (config.url != x) {
        const store = useStore();
        const ref = storeToRefs(store);
     
        if (!ref.isAuthenticated.value) {    
            store.removeUserData(store.$state);         
            window.location.href = '/login';     
        } else {
            const token = store.getToken;
            config.headers['Authorization']= `Bearer ${token.token}`;
        }
    }

    return config;
});
axios.interceptors.response.use(config => config, (error) => {

    if (error.response.status == 401){
       
        window.location.href = '/login';
        useStore().removeUserData();
      
    }

    return Promise.reject(error);
  });

export default {
    url:$apiURL+"/",
    get(url) {
        return axios.get(url);
    },
    post(url, form,cofig) {
        return axios.post(url,form,cofig);
    },
    put(url, form,cofig) {
        return axios.put(url, form,cofig);
    },
    delete(url, form) {
        return axios.delete(url, form);
    },
    getFile(url, config){
        return axios.get(url, config)
    }
}
