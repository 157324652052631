import navbar from './navbar/navbar.vue'
import sidebar from './sidebar/sidebar.vue'
import footerbar from './footerbar/footerbar.vue'
export default {
    name: 'layout',
    components:{
        navbar,
        sidebar,
        footerbar

    },
    created(){
        this.$blockUI.$loading = this.$loading;

    },
    data() {
        return {

        };
    },
}